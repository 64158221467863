import { getMinorCurrencyAmountFromFloat } from '@/utils'
import { digitsFormatter, whitespaceFormatter } from '@/utils/@formatters'
import { BICSchema, SortCodeSchema } from '@/utils/@validators'
import { chain, identity, isArray, isNil, isString, toArray } from 'lodash'
import { PaymentApprovalRuleAttributeSchema, PaymentApprovalRuleCriteria } from '@webapps/numeral-ui-core'

function isInternalAccountNumberKey(key?: string): boolean {
    return key === 'internal_account_number'
}
function isAccountNumberKey(key?: string): boolean {
    return key === 'account_number'
}

function isBankCodeKey(key?: string): boolean {
    return key === 'bank_code'
}

function isAmountKey(key?: string): boolean {
    return key === 'amount'
}

function isAmountFromKey(key?: string): boolean {
    return key === 'amount_from'
}

function isAmountToKey(key?: string): boolean {
    return key === 'amount_to'
}

function isPaymentApprovalRuleCriteriaKey(key?: string): boolean {
    return key === 'criteria'
}

function accountNumberValueTransformer(value: string) {
    const transformer = whitespaceFormatter
    return transformer(value)
}

function bankCodeValueTransformer(value: string) {
    let transformer

    switch (true) {
        case SortCodeSchema.safeParse(value).success: {
            transformer = digitsFormatter
            break
        }

        case BICSchema.safeParse(value).success:
        default: {
            transformer = identity
        }
    }

    return transformer(value)
}

export function paymentApprovalRuleCriteriaTransformer(value: PaymentApprovalRuleCriteria[]) {
    const criterionAmountMapper = (criterion: PaymentApprovalRuleCriteria) => {
        switch (criterion.attribute) {
            case PaymentApprovalRuleAttributeSchema.enum.amount_from:
            case PaymentApprovalRuleAttributeSchema.enum.amount_to: {
                return {
                    ...criterion,
                    values: criterion.values.map((value: unknown) => {
                        if (isString(value)) {
                            const amount = globalThis.Number(value)
                            return getMinorCurrencyAmountFromFloat(amount, criterion.extra)
                        }

                        return value
                    })
                }
            }

            default: {
                return criterion
            }
        }
    }
    const falsyValuesCriterionFilter = (criterion: PaymentApprovalRuleCriteria) => {
        return criterion?.values?.some(globalThis.Boolean)
    }

    return chain(value).filter(falsyValuesCriterionFilter).map(criterionAmountMapper).value()
}

/**
 * @description
 * Centralised place to transform special fields to their required shape before a request is made.
 * - Transform {any} "bank_code" field;
 * - Transform {any} "account_number";
 * - Transform {any} "internal_account_number";
 * - Transform {any} "amount";
 * - Transform {any} "amount_from";
 * - Transform {any} "amount_to";
 * - Transform {PaymentApprovalRules} "criteria";
 */

export function* markedFieldsTransformers<T>(data?: T): Generator<T, T, any> {
    const payloadWithTransformedFields = Object.create(null)

    if (isNil(data)) {
        return payloadWithTransformedFields
    }

    for (const [key, value] of Object.entries(data as Record<any, any>)) {
        if (value !== null && value !== undefined) {
            switch (true) {
                case isInternalAccountNumberKey(key):
                case isAccountNumberKey(key): {
                    payloadWithTransformedFields[key] = accountNumberValueTransformer(value)
                    break
                }

                case isBankCodeKey(key): {
                    payloadWithTransformedFields[key] = bankCodeValueTransformer(value)
                    break
                }

                case isAmountFromKey(key):
                case isAmountToKey(key):
                case isAmountKey(key): {
                    payloadWithTransformedFields[key] = getMinorCurrencyAmountFromFloat(value, (data as any)?.currency)
                    break
                }

                case isPaymentApprovalRuleCriteriaKey(key): {
                    payloadWithTransformedFields[key] = paymentApprovalRuleCriteriaTransformer(value)
                    break
                }

                // Repeat process when values are records and/or maps:

                case isObject(value): {
                    payloadWithTransformedFields[key] = yield* markedFieldsTransformers(value)
                    break
                }

                case isArray(value): {
                    payloadWithTransformedFields[key] = toArray(yield* markedFieldsTransformers(value as any))
                    break
                }

                default: {
                    payloadWithTransformedFields[key] = value
                }
            }
        }
    }

    return payloadWithTransformedFields
}

export function isObject(value: Record<any, any>): boolean {
    return value != null && typeof value === 'object' && globalThis.Array.isArray(value) === false
}
