import { COLON_SYMBOL } from '@/constants'
import { flattenDeep, getMinorCurrencyAmountFromFloat, locationStateCleanup } from '@/utils'
import { CurrencyCode } from '@webapps/numeral-ui-core'
import { chain, identity, invoke, isBoolean, isEmpty, isUndefined, some, trim } from 'lodash'
import { useCallback, useMemo, useState } from 'react'
import { useLocation } from 'react-router'
import {
    API_QUERY_PARAMS_GROUP_BY_TABLE_ENTRY_STATES,
    GROUP_BY_CONFIG_FLAGS,
    PERSISTED_STORE_STATE,
    PERSISTED_STORE_VISIBILITY,
    USE_API_QUERY_PARAMS_DEFAULT_CONFIGURATION
} from './useAPIQueryParams.const'
import {
    API_QUERY_PARAMS_RESERVED_NAMES,
    APIQueryParamConfiguration,
    APIQueryParamConfigurationFilterBy,
    APIQueryParamConfigurationMap,
    APIQueryParamStateFilterBy,
    APIQueryParamStateGroupBy,
    APIQueryParamStateMap,
    APIQueryParamStateSearch,
    APIQueryParamType,
    APIQueryParamTypes,
    DynamicQueryType,
    ExtractQueryType,
    UseAPIQueryParamsArg,
    UseAPIQueryParamsResult
} from './useAPIQueryParams.types'

export function createUseAPIQueryParam<T = void>(
    type: APIQueryParamType<APIQueryParamTypes> | APIQueryParamTypes,
    context: Record<APIQueryParamTypes, UseAPIQueryParamsResult<typeof type>>
) {
    return (
        configuration?: ExtractQueryType<typeof type, APIQueryParamConfigurationMap<T>>
    ): UseAPIQueryParamsResult<typeof type, T> => {
        const location = useLocation()
        const storeUniqueKey = useMemo(() => {
            const keyTuple: [string, string, string | undefined] = [location.pathname, type, configuration?.uniqueId]
            const keyTupleWrapper = chain(keyTuple)
            return keyTupleWrapper.filter(globalThis.Boolean).map(trim).join(COLON_SYMBOL).value().toString()
        }, [location, type])

        const getPersistedVisibility = useCallback((): boolean => {
            return (configuration?.isPersisted && PERSISTED_STORE_VISIBILITY?.[storeUniqueKey]) ?? false
        }, [configuration?.isPersisted, storeUniqueKey])
        const getPersistedState = useCallback(() => PERSISTED_STORE_STATE?.[storeUniqueKey], [storeUniqueKey])
        const hasPersistedState = useMemo((): boolean => {
            const persistedState = getPersistedState()
            const state = invoke(configuration, 'stateParser', persistedState) || persistedState

            return !isEmpty(state)
        }, [configuration, getPersistedState])

        const initialVisibility = useMemo(() => {
            const state: any = location.state /* @todo Workaround for `location.state` type check*/

            if (state?.[type]) {
                return false
            }

            if (configuration?.isPersisted) {
                return getPersistedVisibility()
            }

            return false
        }, [location, configuration?.isPersisted, getPersistedVisibility])
        const initialState = useMemo<ExtractQueryType<typeof type, APIQueryParamStateMap<T>>>(() => {
            const state: any = location.state /* @todo Workaround for `location.state` type check*/
            const locationState = state?.[type]
            const hasLocationState = globalThis.Boolean(locationState)
            const hasPreselectedState = globalThis.Boolean(
                (configuration as APIQueryParamConfigurationFilterBy<T>)?.preselected
            )

            switch (true) {
                case hasLocationState: {
                    locationStateCleanup(type)
                    return locationState
                }

                case configuration?.isPersisted && hasPersistedState: {
                    return getPersistedState()
                }

                case hasPreselectedState: {
                    return invoke(
                        configuration,
                        'stateParser',
                        (configuration as APIQueryParamConfigurationFilterBy<T>)?.preselected
                    )
                }
            }
        }, [location, type, configuration, hasPersistedState, getPersistedState])

        const [isVisible, setIsVisible] = useState(initialVisibility)
        const [isExpanded, setIsExpanded] = useState(configuration?.isExpanded)
        const [innerState, setInnerState] =
            useState<ExtractQueryType<typeof type, APIQueryParamStateMap<T>>>(initialState)
        const [isExpandedEntries, setIsExpandedEntries] = useState<Record<string, number>>(Object.create(null))
        const parsedState = useMemo<typeof innerState>(() => {
            return invoke(configuration, 'stateParser', innerState)
        }, [innerState, configuration])

        const isActive = useMemo<boolean>(() => {
            return !isEmpty(parsedState) && some(parsedState, (value) => !isEmpty(value))
        }, [parsedState])

        const setState = useCallback(
            (state: typeof innerState) => {
                setInnerState(state)
                setPersistedState(PERSISTED_STORE_STATE, storeUniqueKey, configuration?.isPersisted, state)
            },
            [configuration?.isPersisted, storeUniqueKey]
        )

        const toggleIsVisible = useCallback(
            (forcedValue?: boolean) => {
                setIsVisible((prevState) => {
                    const state = isBoolean(forcedValue) ? forcedValue : !prevState

                    setPersistedState(PERSISTED_STORE_VISIBILITY, storeUniqueKey, configuration?.isPersisted, state)
                    invoke(configuration, 'toggleIsVisible', state)

                    return state
                })
            },
            [context, configuration?.isPersisted, storeUniqueKey]
        )

        const toggleIsExpanded = useCallback(
            (forcedValue?: boolean) => {
                setIsExpanded(forcedValue)
                invoke(configuration, 'toggleIsExpanded', forcedValue)

                if (forcedValue) {
                    setIsExpandedEntries(Object.create(null))
                }
            },
            [context]
        )
        const getToggleGroupByExpandedEntries = useMemo(() => {
            const groupsUniqueKeys = new Set()

            return (uniqueKey: string) => {
                groupsUniqueKeys.add(uniqueKey)

                return (expandedIndex: number) => {
                    setIsExpanded(undefined)

                    setIsExpandedEntries((prevState: Record<string, number>) => {
                        const invertedState =
                            expandedIndex === API_QUERY_PARAMS_GROUP_BY_TABLE_ENTRY_STATES.COLLAPSED
                                ? API_QUERY_PARAMS_GROUP_BY_TABLE_ENTRY_STATES.EXPANDED
                                : API_QUERY_PARAMS_GROUP_BY_TABLE_ENTRY_STATES.COLLAPSED

                        const invertedStates = mapSetToObjectWithDefaultValue(groupsUniqueKeys, invertedState)

                        return {
                            ...invertedStates,
                            ...prevState,
                            [uniqueKey]: expandedIndex
                        }
                    })
                }
            }
        }, [])

        return {
            //Hook flags:
            isActive,
            isVisible,
            isExpanded,
            isGroupByRecordColumnsVisible: GROUP_BY_CONFIG_FLAGS.IS_GROUP_BY_RECORD_COLUMNS_VISIBLE,
            isGroupByWithEmptyRecordsVisible: GROUP_BY_CONFIG_FLAGS.IS_GROUP_BY_WITH_EMPTY_RECORDS_VISIBLE,

            //Hook states:
            parsedState,
            initialState,
            innerState,
            isExpandedEntries,

            //Hook callbacks - general:
            setState,
            toggleIsVisible,
            //Hook callback - groupBy
            toggleIsExpanded,
            getToggleGroupByExpandedEntries,

            //Hook configs:
            configuration: configuration as any
        }
    }
}

export function APIqueryParamArgTypeResolver<T = void>(arg: UseAPIQueryParamsArg<T>): DynamicQueryType<typeof arg> {
    let type: DynamicQueryType<typeof arg>

    switch (typeof arg) {
        case 'string':
            type = arg
            break

        case 'object':
        default:
            type = chain(arg).keys().head().value() as DynamicQueryType<typeof arg>
            break
    }

    return type
}

export function getQueryConfigurationByType<T>(
    type: APIQueryParamType<APIQueryParamTypes> | APIQueryParamTypes,
    config?: ExtractQueryType<typeof type, APIQueryParamConfigurationMap<T>>
) {
    let configuration: Partial<ExtractQueryType<typeof type, APIQueryParamConfigurationMap<T>>>

    switch (type) {
        case APIQueryParamTypes.FilterBy: {
            configuration = {
                stateParser: filterByStateParser<T>
            }
            break
        }

        case APIQueryParamTypes.GroupBy: {
            configuration = {
                isExpanded: false,
                APIQueryParamKey: API_QUERY_PARAMS_RESERVED_NAMES.GROUP_BY,
                stateParser: groupByStateParser
            }
            break
        }

        case APIQueryParamTypes.Search: {
            configuration = {
                isEnabled: true,
                APIQueryParamKey: API_QUERY_PARAMS_RESERVED_NAMES.SEARCH,
                stateParser: searchStateParser
            }
            break
        }

        default: {
            configuration = {
                stateParser: identity
            } as APIQueryParamConfiguration<any, T>
        }
    }

    return Object.assign(configuration, USE_API_QUERY_PARAMS_DEFAULT_CONFIGURATION, config)
}

export function searchStateParser(state?: APIQueryParamStateSearch): typeof state | undefined {
    const stateWrapper = chain(state).get(API_QUERY_PARAMS_RESERVED_NAMES.SEARCH)

    if (stateWrapper.isEmpty().value()) {
        return
    }

    return {
        [API_QUERY_PARAMS_RESERVED_NAMES.SEARCH]: stateWrapper.value()
    }
}

export function groupByStateParser(state?: APIQueryParamStateGroupBy): typeof state {
    const stateWrapper = chain(state).get(API_QUERY_PARAMS_RESERVED_NAMES.GROUP_BY)

    if (isUndefined(stateWrapper.value())) {
        return
    }

    return {
        [API_QUERY_PARAMS_RESERVED_NAMES.GROUP_BY]: stateWrapper.toArray().filter(globalThis.Boolean).value()
    }
}

/**
 * @description
 * - Convert the filterBy values to align with the API contract.
 * - These values can be used by any request type (GET/POST/PUT etc.),
 *   as such they have to respect OpenAPI contract value formatting AND types as well (string, int, etc.).
 *
 * - Special cases:
 * Amounts:
 * 1. `amount_from` has to be an {Amount}* type;
 * 2. `amount_to` has to be a {Amount}* type.
 * * {Amount} is a type alias for type {Number}
 */
export function filterByStateParser<T = void>(state?: APIQueryParamStateFilterBy<T>): typeof state {
    if (!state) {
        return
    }

    return Object.entries(flattenDeep(state)).reduce((acc, [name, value]: any) => {
        if (!value) {
            return acc
        }

        switch (name) {
            case 'matching_amount':
            case 'amount_from':
            case 'amount_to': {
                const currencyCode = chain<typeof state & { currency?: CurrencyCode }>(state).get('currency').value()

                if (currencyCode) {
                    const amount = globalThis.Number(value)
                    acc[name] = getMinorCurrencyAmountFromFloat(amount, currencyCode as CurrencyCode)
                } else {
                    acc[name] = value
                }

                break
            }
            default: {
                acc[name] = value
            }
        }

        return acc
    }, Object.create(null))
}

export function setPersistedState(
    storeLocation: Record<string, any>,
    currentPathname: string,
    isPersisted?: boolean,
    state?: any
) {
    if (isPersisted) {
        storeLocation[currentPathname] = state
    }
}

function mapSetToObjectWithDefaultValue(set?: Set<any>, value?: any) {
    const acc = Object.create(null)

    if (set instanceof Set) {
        return Array.from(set).reduce((acc, item, index) => {
            acc[item] = value
            return acc
        }, acc)
    }

    return acc
}
